import { $fetch, $post } from '@/services';

export function load(id) {
  return $fetch(`/cash/customerWithdrawalFlow/${id}`);
}

export function fetch(data) {
  return $fetch(`/cash/customerWithdrawalFlow/search`, data);
}

export function approval(data) {
  return $post(`/cash/customerWithdrawalFlow/approval`, data);
}

export function approvalAndProcess(data) {
  return $post(`/cash/customerWithdrawalFlow/approvalAndProcess`, data);
}
